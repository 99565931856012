import React from 'react';
import { graphql } from 'gatsby';
import { Element } from 'react-scroll';
import SEO from 'components/seo/seo';
import Layout from 'layouts/Layout';
import SubpageWrapper from 'components/Subpage/SubpageWrapper/SubpageWrapper';
import SectionList from 'components/Subpage/SectionList/SectionList';
import Heading from 'components/Subpage/Heading/Heading';
import Footer from 'components/Footer/Footer';

const PageTemplate = ({ data }) => {
  const pageId = data.strapiPages.strapiId;
  const relatedPage = data.allStrapiPages.nodes.find(item =>
    item.localizations.find(itemInner => itemInner.id === pageId)
  );

  let relatedPages;

  if (relatedPage) {
    relatedPages = [
      {
        slug: data.strapiPages.slug,
        locale: data.strapiPages.locale,
      },
      {
        slug: relatedPage.slug,
        locale: relatedPage.locale,
      },
    ];
  }

  const imageUrl = `${data.strapiPages.SEO.meta_image.url}`.includes(
    'admin.botaretinol.eu'
  )
    ? `${data.strapiPages.SEO.meta_image.url}`
    : `https://admin.botaretinol.eu${data.strapiPages.SEO.meta_image.url}`;

  return (
    <Layout relatedPages={relatedPages}>
      <SEO
        title={data.strapiPages.SEO.meta_title}
        description={data.strapiPages.SEO.meta_description}
        image={imageUrl}
        imgAlt={data.strapiPages.SEO.image_alt}
      />
      <SubpageWrapper>
        <Heading>{data.strapiPages.title}</Heading>
        <SectionList sections={data.strapiPages.Section} />
      </SubpageWrapper>
      <Element name="navigation_footer">
        <Footer />
      </Element>
    </Layout>
  );
};

export default PageTemplate;

export const query = graphql`
  query PageTemplate($slug: String, $locale: String) {
    strapiPages(slug: { eq: $slug }, locale: { eq: $locale }) {
      title
      Section
      strapiId
      locale
      slug
      SEO {
        meta_title
        meta_description
        image_alt
        meta_image {
          url
        }
      }
    }
    allStrapiPages {
      nodes {
        slug
        locale
        localizations {
          id
        }
      }
    }
  }
`;
